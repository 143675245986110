var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h5',{staticClass:"vb-heading"},[_vm._v("Dati di fatturazione")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return _vm._l((_vm.guest),function(field,key){return _c('validation-provider',{key:'input_'+key,attrs:{"rules":field.rules,"name":key,"vid":key},scopedSlots:_vm._u([{key:"default",fn:function({ errors, validate, failedRules }){return [(field.type == 'input')?_c('div',[_c('label',{staticClass:"vb-label",domProps:{"innerHTML":_vm._s(field.name)}}),_c('vb-input',{attrs:{"vb_options":{
            name: key,
            placeholder: field.name,
          }},on:{"input":function($event){return _vm.validate_fields($event,validate,key)},"change":function($event){return _vm.validate_fields($event,validate,key)},"blur":function($event){return _vm.validate_fields($event,validate,key)}},model:{value:(_vm.guest[key].value),callback:function ($$v) {_vm.$set(_vm.guest[key], "value", $$v)},expression:"guest[key].value"}}),_vm._l((failedRules),function(rule,index){return _c('div',{key:_vm._uid+index},[(index!=='required')?_c('span',{staticClass:"vee--errors"},[_vm._v(_vm._s(rule.replace(key, field.name)))]):_vm._e()])})],2):(field.type == 'checkbox')?_c('div',[_c('vb-checkbox',{attrs:{"vb_options":{
            name: key,
            text: '<p>'+field.text+'</p>',
          }},on:{"change":function($event){return _vm.validate_fields($event,validate,key)}},model:{value:(_vm.guest[key].value),callback:function ($$v) {_vm.$set(_vm.guest[key], "value", $$v)},expression:"guest[key].value"}})],1):_vm._e()]}}],null,true)})})}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }