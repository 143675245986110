var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h5',{staticClass:"vb-heading"},[_vm._v("Recapito di viaggio")]),(_vm.loading_beneficiaries)?_c('loader'):_vm._e(),(_vm.loading_beneficiaries == false)?_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.allowCustomBeneficiary),expression:"!allowCustomBeneficiary"}]},[_c('label',{staticClass:"vb-label",domProps:{"innerHTML":_vm._s('Viaggiatore principale')}}),_c('b-form-select',{attrs:{"required":true,"options":_vm.beneficiaries},on:{"change":function($event){return _vm.update_beneficiary()}},model:{value:(_vm.beneficiaryId),callback:function ($$v) {_vm.beneficiaryId=$$v},expression:"beneficiaryId"}})],1),_vm._l((_vm.guest),function(field,key){return _c('validation-provider',{key:'input_'+key,attrs:{"rules":field.rules,"name":key,"vid":key},scopedSlots:_vm._u([{key:"default",fn:function({ errors, validate, failedRules }){return [(['guestFirstName','guestLastName', 'guestFiscalCode', 'guestEmail', 'guestEmailConf'].includes(key) == true)?_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.allowCustomBeneficiary | _vm.is_development()),expression:"allowCustomBeneficiary | is_development()"}]},[_c('label',{staticClass:"vb-label",domProps:{"innerHTML":_vm._s(field.name)}}),_c('vb-input',{attrs:{"vb_options":{
              name: key,
              placeholder: field.name,
              disabled: !_vm.allowCustomBeneficiary,
            }},on:{"input":function($event){return _vm.validate_fields($event,validate,key)},"change":function($event){return _vm.validate_fields($event,validate,key)},"blur":function($event){return _vm.validate_fields($event,validate,key)}},model:{value:(_vm.guest[key].value),callback:function ($$v) {_vm.$set(_vm.guest[key], "value", $$v)},expression:"guest[key].value"}}),_vm._l((failedRules),function(rule,index){return _c('div',{key:_vm._uid+index},[(index!=='required')?_c('span',{staticClass:"vee--errors"},[_vm._v(_vm._s(rule.replace(key, field.name)))]):_vm._e()])})],2)]):(field.type == 'input')?_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"vb-label",domProps:{"innerHTML":_vm._s(field.name)}}),_c('vb-input',{attrs:{"vb_options":{
            name: key,
            placeholder: field.name,
          }},on:{"input":function($event){return _vm.validate_fields($event,validate,key)},"change":function($event){return _vm.validate_fields($event,validate,key)},"blur":function($event){return _vm.validate_fields($event,validate,key)}},model:{value:(_vm.guest[key].value),callback:function ($$v) {_vm.$set(_vm.guest[key], "value", $$v)},expression:"guest[key].value"}}),_vm._l((failedRules),function(rule,index){return _c('div',{key:_vm._uid+index},[(index!=='required')?_c('span',{staticClass:"vee--errors"},[_vm._v(_vm._s(rule.replace(key, field.name)))]):_vm._e()])})],2):(field.type == 'checkbox')?_c('div',[_c('vb-checkbox',{attrs:{"vb_options":{
            name: key,
            text: '<p>'+field.text+'</p>',
          }},on:{"change":function($event){return _vm.validate_fields($event,validate,key)}},model:{value:(_vm.guest[key].value),callback:function ($$v) {_vm.$set(_vm.guest[key], "value", $$v)},expression:"guest[key].value"}})],1):_vm._e()]}}],null,true)})})]}}],null,false,4019309714)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }