<template>
  <section class="section site-prefooter">
    <b-container>
      <div class="text-center mb-4">
        <div class="vb-heading h5">
          Parla con noi
        </div>
        <p>
          dal lunedì al venerdì, dalle 9:00 alle 19:00
        </p>
      </div>
      <b-row class="dynamic-cols">
        <b-col sm="6" lg="3" v-if="get_config().site.emailSupport">
          <div class="site-prefooter__block">
            <vb-icon class="site-prefooter__icon" :name="'email'" :dotted="true" />
            <div class="h6 vb-heading site-prefooter__title">
              Assistenza email
            </div>
            <div class="site-prefooter__text">
              <a :href="'mailto:'+get_config().site.emailSupport" v-html="get_config().site.emailSupport"/>
            </div>
          </div>
        </b-col>
        <b-col sm="6" lg="3" v-if="get_config().site.phoneNumber">
          <div class="site-prefooter__block">
            <vb-icon class="site-prefooter__icon" :name="'phone'" :dotted="true" />
            <div class="h6 vb-heading site-prefooter__title">
              Assistenza telefonica
            </div>
            <div class="site-prefooter__text">
              <a :href="'phone:'+get_config().site.phoneNumber" v-html="get_config().site.phoneNumber+' | Chiama ora'"/>
            </div>
          </div>
        </b-col>
        <b-col sm="6" lg="3" v-if="get_config().site.webChat">
          <div class="site-prefooter__block">
            <vb-icon class="site-prefooter__icon" :name="'chat'" :dotted="true" />
            <div class="h6 vb-heading site-prefooter__title">
              Webchat
            </div>
            <div class="site-prefooter__text">
              <a href="javascript:window.zE.activate()" v-html="'Inizia la chat!'"/>
            </div>
          </div>
        </b-col>
        <b-col sm="6" lg="3" v-if="get_config().site.mobileNumber">
          <div class="site-prefooter__block">
            <vb-icon class="site-prefooter__icon" :name="'whatsapp'" :color="'25D366'" />
            <div class="h6 vb-heading site-prefooter__title">
              WhatsApp
            </div>
            <div class="site-prefooter__text">
              <a :href="get_config().site.waIntentLink" v-html="get_config().site.mobileNumber + ' | Parla con noi!'"/>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: 'prefooter',
  components:
  {
  },
  created() {
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="scss">
.site-prefooter {
  margin-top: $grid-gutter-width*2;
  &__block {
    background: $white;
    text-align: center;
    border-radius: $border-radius;
    padding: $grid-gutter-width 0;
    margin: $grid-gutter-width/3 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__icon {
    display: flex;
    align-items: center;
    margin-bottom: $grid-gutter-width/2;
    .vb-icon {
      margin: auto;
      margin-bottom:1rem;
      svg {
        width: auto;
        height: 30px;
      }
    }
  }
  &__title {
  }
  &__text {
    @include font-primary-semibold;
    font-size: 0.8125rem;
  }

  @media print {
    display: none;
  }

  .dynamic-cols > * {
    max-width: none;
    flex: 1;
  }
}
</style>